.navbar {
    background: transparent;
    width: 100%;
    padding: 10px;
    background: #b20c13;
}
.principalHome{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/home.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.principalHome2{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/home2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.road{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/road.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.cerca2{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/cerca2.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.cerca{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/cerca.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.puas{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/puas.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.about-game {
    text-align: right;
    text-transform: uppercase;
    color: white;
    font-size: 36px;
    font-weight: 900;
    span {
        color: #ff3f30;
    }
}
.container-roadmap{
    position: relative;
    border-top: 1px solid rgb(126, 126, 126);
}
.oval{
    width: 30px;
    height: 30px;
    background: red;
    position: absolute;
    top: -16px;
    border-radius: 50px;
}
.title-month {
    text-align: right;
    text-transform: capitalize;
    color: #4a4a4a;
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 10px;
}

.description-point {
    text-align: right;
    text-transform: capitalize;
    font-size: 14px;
    color: #6a6a6a;
    font-weight: 900;
    margin-bottom: 10px;
}